import * as React from "react";

export function useElementIDNavigation(selector: string) {
  React.useEffect(() => {
    let timeout: NodeJS.Timeout;

    const handleScroll = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        let found = false;

        document.querySelectorAll(selector).forEach((e) => {
          //element top position on dom
          const { top } = e.getBoundingClientRect();
          //parent element height
          const height = e.parentElement?.getBoundingClientRect().height || 0;

          //we only need the first occurrence
          if (!found && top + height > 0) {
            found = true;
            const id = e.getAttribute("id");

            if (id) {
              //removing the id form the element so it doesn't scroll when changing the hash
              e.setAttribute("id", "");
              //changes the location hash
              window.location.hash = id;

              setTimeout(() => {
                //restores the element id
                e.setAttribute("id", id);
              }, 100);
            }
          }
        });
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
}
