import * as React from "react";

import { docSection, docSectionOffset } from "./DocSection.module.scss";

interface DocSectionProps {
  id: string;
  html: string;
}

export function DocSection(props: DocSectionProps) {
  return (
    <div className={docSection}>
      <div id={props.id} className={docSectionOffset} />
      <div dangerouslySetInnerHTML={{ __html: props.html }} />
    </div>
  );
}
